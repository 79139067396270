import axios from "axios";
import Cookies from "js-cookie";
import React, { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import { AiOutlineDelete } from "react-icons/ai";
import { BiUserCircle } from "react-icons/bi";
import "./customer.css";

const Customers = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    const FetchUsers = async () => {
      try {
        const res = await axios.get(
          process.env.REACT_APP_BASE_URL + "/api/admin/getusers",
          { headers: { token: Cookies.get("token") } }
        );
        setData(res.data.users);
      } catch (error) {
        console.log(error);
      }
    };
    FetchUsers();
  }, []);

  console.log(data);

  return (
    <div className="content">
      <div className="Products">
        <h1 className="fs-5 fw-bold">Customers</h1>
        <div className="searchBox">
          <input
            type={"text"}
            placeholder="Search By Name"
            className="form-control input rounded"
          />
        </div>

        <Table responsive className="Tablee mt-3">
          <thead>
            <tr>
              <th>Profile</th>
              <th>Name</th>
              <th>Email</th>
              <th>Phone</th>
              <th>City</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {data.map((elem) => {
              return (
                <tr key={elem._id}>
                  <td className="text-center">
                    {elem?.img?.length > 0 ? (
                      <img
                        src={process.env.REACT_APP_BASE_URL + "/" + elem.img}
                        alt="img"
                        width={100}
                      />
                    ) : (
                      <BiUserCircle size={30} className="gray" />
                    )}
                  </td>
                  <td>{elem.name}</td>
                  <td>{elem.email}</td>
                  <td>{elem.phone}</td>
                  <td>{elem.city}</td>
                  <td>
                    <div className="d-flex justify-content-center">
                      <AiOutlineDelete
                        size={23}
                        cursor={"pointer"}
                        className="text-danger fw-bold"
                        // onClick={() => handleDelete(elem._id)}
                      />
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>
    </div>
  );
};

export default Customers;
