import React from "react";
import { IoMdNotifications } from "react-icons/io";
import { AiOutlineMenu } from "react-icons/ai";

const Navbar = ({ setShow, show }) => {
  return (
    <nav className="Navbar d-flex justify-content-end p-3">
      {window.matchMedia("(max-width: 1023px)").matches && (
        <AiOutlineMenu
          size={25}
          className="humburger"
          onClick={() => setShow(!show)}
          cursor="pointer"
        />
      )}
      <div>
        <IoMdNotifications size={25} cursor="pointer" />
      </div>
    </nav>
  );
};

export default Navbar;
