import React, { useEffect, useState } from "react";
import "./products.css";
import Select from "react-dropdown-select";
import Tablee from "../../components/Tablee";
import ProductModal from "../../components/ProductModal";
import EditModal from "../../components/EditModal";
import axios from "axios";

const Products = () => {
  const [gender, setGender] = useState("");
  const [modal, setModal] = useState(false);
  const [editmodal, setEditModal] = useState(false);
  const [products, setProducts] = useState([]);


  const Categoryoptions = [
    { label: "Smartphones", value: 1 },
    { label: "Wearables", value: 2 },
    { label: "Smart Home", value: 3 },
  ];

  const FetchProducts = async () => {
    try {
      const res = await axios.get(process.env.REACT_APP_BASE_URL + "/api/products/getproducts");
      setProducts(res.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    FetchProducts();
  }, []);

  return (
    <div className="content">
      <div className="Products">
        <h1 className="fs-5 fw-bold">Products</h1>
        <div className="searchBox">
          <input
            type={"text"}
            placeholder="Search By Name"
            className="form-control input rounded"
          />
            <Select
              className="input rounded"
              options={
                Categoryoptions
              }
              onChange={(values) => setGender(values)}
            />
          <button className="button" onClick={() => setModal(true)}>
            Add Product
          </button>
        </div>
        <Tablee
          title={""}
          data={products}
          FetchProducts={FetchProducts}
        />
      </div>
      {modal && <ProductModal setShow={setModal} show={modal} FetchProducts={FetchProducts}/>}
    </div>
  );
};

export default Products;
