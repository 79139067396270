import React from "react";
import Vcard from "../../components/Vcard";
import "./home.css";
import { MdPendingActions, MdProductionQuantityLimits } from "react-icons/md";
import { BiShoppingBag } from "react-icons/bi";
import { RiMoneyDollarCircleLine } from "react-icons/ri";
import { AiOutlineShoppingCart } from "react-icons/ai";
import { TbTruckDelivery } from "react-icons/tb";
import Hcard from "../../components/Hcard";
import Tablee from "../../components/Tablee";
import { Navigate } from "react-router-dom";
import Cookies from "js-cookie";

const Home = () => {

  if (!Cookies.get("token")) return <Navigate to={"/login"} />;

  return (
    <div className="px-3 content">
      <h1 className="fs-5 fw-bold">Dashboard overview</h1>
      <div className="cards-grid">
        <Vcard
          bg={"primary"}
          icon={<MdProductionQuantityLimits size={40} />}
          title={"Today Orders"}
          amount={"30"}
        />
        <Vcard
          bg={"blue"}
          icon={<BiShoppingBag size={40} />}
          title={"This Month"}
          amount={"110"}
        />
        <Vcard
          bg={"orange"}
          icon={<RiMoneyDollarCircleLine size={40} />}
          title={"Monthly Total Amount"}
          amount={"$3000"}
        />
      </div>
      {/* <div className="cards-grid">
        <Hcard
          bg={"blue"}
          icon={<AiOutlineShoppingCart size={30} color="white" />}
          title={"Total Orders"}
          amount={"130"}
        />
        <Hcard
          bg={"orange"}
          icon={<MdPendingActions size={30} color="white" />}
          title={"Pending Orders"}
          amount={"30"}
        />
        <Hcard
          bg={"primary"}
          icon={<TbTruckDelivery size={30} color="white" />}
          title={"Delivered Orders"}
          amount={"100"}
        />
      </div> */}
      <div className="mt-4">
        <Tablee
          title={"Recent Orders"}
          data={[]}
          th={[
            "ORDER TIME",
            "DELIVERY ADDRESS",
            "PHONE",
            "PAYMENT METHOD",
            "ORDER AMOUNT",
            "STATUS",
          ]}
          tr={[
            [
              "3 dec 2022",
              "34 M.3 ,San Pee Seua, Muang, Chiangmai",
              "0850882198",
              "COD",
              "$86.00",
              "STATUS",
            ],
            [
              "4 dec 2022",
              "34 M.3 ,San Pee Seua, Muang, Chiangmai",
              "0850882198",
              "COD",
              "$86.00",
              "STATUS",
            ],
            [
              "5 dec 2022",
              "34 M.3 ,San Pee Seua, Muang, Chiangmai",
              "0850882198",
              "COD",
              "$86.00",
              "STATUS",
            ],
          ]}
        />
      </div>
    </div>
  );
};

export default Home;
