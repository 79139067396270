import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./pages/home/Home";
import Navbar from "./components/Navbar";
import SideBar from "./components/SideBar";
import Products from "./pages/products/Products";
import Customers from "./pages/customers/Customers";
import { useState } from "react";
import Login from "./pages/login/Login";
import Cookies from "js-cookie";
import Order from "./pages/order/Order";

function App() {
  const user = Cookies.get('token');
  const [show, setShow] = useState(false);

  return (
    <>
      <Router>
        {user && <Navbar setShow={setShow} show={show} />}
        <div className="d-flex">
          {user && <SideBar show={show} setShow={setShow} />}
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/products" element={<Products />} />
            <Route path="/customers" element={<Customers />} />
            <Route path="/orders" element={<Order />} />
            <Route path="/login" element={<Login />} />
          </Routes>
        </div>
      </Router>
    </>
  );
}

export default App;
