import axios from "axios";
import React, { useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import "./login.css";
import jwt_decode from "jwt-decode";
import Cookies from "js-cookie";

const Login = () => {
  const [credentials, setCredentials] = useState({});
  const navigate = useNavigate();

  const handleChange = (e) => {
    setCredentials((prev) => ({ ...prev, [e.target.id]: e.target.value }));
  };

  const handlelogin = async () => {
    try {
      const res = await axios.post(
        process.env.REACT_APP_BASE_URL + `/api/auth/login`,
        credentials,
        {
          headers: { "Content-Type": "application/json" },
        }
      );
      const json = await res.data;
      setCredentials({ email: "", password: "" });
      var decodedJWT = jwt_decode(json.authToken);
      if (json.success && decodedJWT.isAdmin) {
        Cookies.set('token', json.authToken, { sameSite: 'strict', secure: true })
        window.location.reload();
      } else {
        alert("Something went wrong!", "danger");
      }
    } catch (err) {
      console.log(err.response.data);
    }
  };


  // console.log(credentials);
  
  if (Cookies.get('token')) return <Navigate to={"/"} />;

  return (
    <div className="d-flex justify-content-center align-items-center Login">
      <div className="LoginBox">
        <h1 className="fs-3 fw-bold text-center">Tutiendadelmovil</h1>
        <div className="mt-2">
          <label>Email</label>
          <input
            type={"email"}
            placeholder="Email"
            className="mt-1 form-control"
            id="email"
            onChange={(e) => handleChange(e)}
          />
        </div>
        <div className="mt-2">
          <label>Password</label>
          <input
            type={"password"}
            placeholder="Password"
            className="mt-1 form-control"
            id="password"
            onChange={(e) => handleChange(e)}
          />
          <button
            className="button primary text-light mt-2 py-2 px-3"
            onClick={handlelogin}
          >
            Login
          </button>
        </div>
      </div>
    </div>
  );
};

export default Login;
