import React from "react";

const Vcard = (props) => {
  return (
    <div className={`Vcard ${props.bg} me-3 mt-3 text-center`}>
      {props.icon}
      <p className="fs-6 mt-1">{props.title}</p>
      <p className="fs-4 mt-1">{props.amount}</p>
    </div>
  );
};

export default Vcard;
