import Cookies from "js-cookie";
import React from "react";
import { AiFillShop, AiOutlineAppstore } from "react-icons/ai";
import { BiCoinStack } from "react-icons/bi";
import { FiShoppingCart, FiUsers } from "react-icons/fi";
import { Link, useLocation } from "react-router-dom";

const SideBar = ({ show, setShow }) => {
  const location = useLocation();
  const close = () => setShow(false);

  return (
    <>
      {(window.matchMedia("(min-width: 1024px)").matches || show) && (
        <aside className="sidebar pb-3 py-2">
          <Link to={"/"} className="d-flex logo px-3" onClick={close}>
            {/* <h2 className="fw-bold">BeSassy.</h2> */}
            <AiFillShop color="#0694a2" size={50}/>
          </Link>
          <Link
            to={"/"}
            className={`d-flex align-items-center px-3 py-2 mt-4 ${
              location.pathname === "/" && "active"
            }`}
            onClick={close}
          >
            {location.pathname === "/" && <span className="activeSpan"></span>}
            <AiOutlineAppstore size={25} />
            <p className="ms-3">Dashboard</p>
          </Link>
          <Link
            onClick={close}
            to={"/products"}
            className={`d-flex align-items-center px-3 py-2 mt-3 ${
              location.pathname === "/products" && "active"
            }`}
          >
            {location.pathname === "/products" && (
              <span className="activeSpan"></span>
            )}
            <FiShoppingCart size={25} />
            <p className="ms-3">Products</p>
          </Link>
          <Link
            onClick={close}
            to={"/customers"}
            className={`d-flex align-items-center px-3 py-2 mt-3 ${
              location.pathname === "/customers" && "active"
            }`}
          >
            {location.pathname === "/customers" && (
              <span className="activeSpan"></span>
            )}
            <FiUsers size={25} />
            <p className="ms-3">Customers</p>
          </Link>
          <Link
            onClick={close}
            to={"/orders"}
            className={`d-flex align-items-center px-3 py-2 mt-3 ${
              location.pathname === "/orders" && "active"
            }`}
          >
            {location.pathname === "/orders" && (
              <span className="activeSpan"></span>
            )}
            <BiCoinStack size={25} />
            <p className="ms-3">Orders</p>
          </Link>
          <div className="px-3 py-2 mt-3 sidebar-btn">
            <button
              className="button"
              onClick={() => {
                Cookies.remove("token");
                window.location.reload();
              }}
            >
              Log Out
            </button>
          </div>
        </aside>
      )}
    </>
  );
};

export default SideBar;
